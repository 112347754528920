import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import excelCss from "./excel.module.css";

const Excel = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "excel-pro.png" }) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return <Img fixed={data.file.childImageSharp.fixed} className={excelCss.Img}/>
}

export default Excel
