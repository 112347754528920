import React from "react"
import desarrolloCss from "../../css/desarrollo.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ExcelIMG from "../../components/images/Excel/Excel"
import MenuDesarrollo from "../../components/menuDesarrollo/menuDesarrollo"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const Excel = () => (
  <>
    <div className={desarrolloCss.background}>
      <div className={desarrolloCss.gradient}></div>
    </div>
    <ExcelIMG></ExcelIMG>
    <Link to="/" className={desarrolloCss.logoBlanco}>
    <img src={LogoBlanco} alt="Logotipo de engrane version blanca"/>
    </Link>
    <MenuDesarrollo></MenuDesarrollo>
    <div className={desarrolloCss.container}>
      <Layout siteTitle="Desarrollo">
        <SEO title="Macros de Excel" />
        <div className={desarrolloCss.contenido}>
          <h1 className={desarrolloCss.titulo}>
          Excel <br/>
pro
          </h1>
          <p className={desarrolloCss.text}>
          ¿Tu empresa es de las que tienen mucha información en una hoja de Excel? ¿Y necesitas encontrar datos específicos, sin tener que buscar columna por columna? Nosotros podemos ayudarte a optimizar tiempo y generar tus
reportes de manera prácticamente automática
          </p>
          <br/>
          <p className={desarrolloCss.text}>
          ¡Sabemos que te solucionará muchos problemas!
          </p>
        </div>
      </Layout>
    </div>
    <Link className={desarrolloCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default Excel
